const version = 'v1/';

export default class Endpoints {
  static token = `${version}token/`;
  static refreshToken = `${version}token/refresh/`;
  static recoveryPassword = `${version}auth/users/reset_password/`;
  static resetPassword = `${version}auth/users/reset_password_confirm/`;
  static users = `${version}user_info`;
  static user_reset_password = `${version}user_reset_password/`;
  static user_emails = `${version}emails`;
  static user_email = `${version}user_email/`;
  static proposals = `${version}proposals`;
  static proposal_notes = `${version}proposal_notes`;
  static proposals_info = `${version}proposals_info`;
  static proposals_count = `${version}proposals_count`;
  static proposals_export = `${version}proposals_export`;
  static messages = `${version}messages`;
  static messages_send_mail = `${version}messages_send_mail`;
  static products_files = `${version}products_files`;
  static files = `${version}files`;
  static insurances = `${version}insurance`;
  static insurance_info = `${version}insurance_info`;
  static terms_of_use = `${version}terms_of_use`;
  static companies = `${version}companies`;
  static products = `${version}products`;
  static product_companies = `${version}product_companies`;
  static liveness = `${version}liveness`;
  static certificates = `${version}certificates`;
  static clients = `${version}clients`;
  static chat_bot_hours = `${version}chat_bot_hours`;
  static chat_bot_config = `${version}chat_bot_config`;
  static holiday = `${version}holidays_config`;
  static services = `${version}services`;
  static services_export = `${version}services_export`;
  static services_count = `${version}services_count`;
  static new_service = `${version}new_service`;
  static deadline_count = `${version}deadline_count`;
  static interest_rate = `${version}interest_rate`;
  static dashboard = `${version}dashboard`;
  static sites = `${version}sites`;
  static banners = `${version}banners`;
  static posts = `${version}posts`;
  static properties = `${version}propriedades`;
  static credit_card = `${version}credit_card`;
  static credit_card_info = `${version}credit_card_info`;
  static credit_card_export = `${version}credit_card_export`;
  static credit_card_rate = `${version}credit_card_rate`;
  static permissions_profile = `${version}permissions_profile`;
  static portalcliente_clients = `portalcliente/${version}clients`;
  static portalcliente_client_companies = `portalcliente/${version}client_companies`;
}

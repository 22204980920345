import moment from 'moment';

export const containsProfile = (userData, profiles) => {
  const userDataProfiles = userData?.profiles ?? [];
  if (userDataProfiles.length > 0) {
    return profiles.some(profile => userDataProfiles.indexOf(profile) >= 0);
  }
  return false;
};

export const getCPFCNPJ = (cpfcnpj, format = true) => {
  if (cpfcnpj && cpfcnpj.length > 1) {
    if (cpfcnpj[1] === '-') {
      cpfcnpj = cpfcnpj.substring(2, cpfcnpj.length - 1);
    }
    if (format) {
      if (cpfcnpj.length > 11) {
        if (cpfcnpj.length === 12) {
          return cpfcnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '$1.$2.$3/$4-');
        } else {
          return cpfcnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }
      } else {
        if (cpfcnpj.length === 9) {
          return cpfcnpj.replace(/(\d{3})(\d{3})(\d{3})/, '$1.$2.$3-');
        } else {
          return cpfcnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        }
      }
    }
  }
  return cpfcnpj;
};

export const getPartnerCNPJ = (cpfcnpj, format = true) => {
  if (cpfcnpj && cpfcnpj.length > 1) {
    if (cpfcnpj[1] === '-') {
      cpfcnpj = cpfcnpj.split('-')[1];
    }
  }
  return getCPFCNPJ(cpfcnpj, format);
};

export const getPartnerCPF = (cpfcnpj, format = true) => {
  if (cpfcnpj && cpfcnpj.length > 1) {
    if (cpfcnpj[1] === '-') {
      const ar = cpfcnpj.split('-');
      if (ar.length === 3) {
        cpfcnpj = ar[2];
      }
    }
  }
  return getCPFCNPJ(cpfcnpj, format);
};

export const getPartnerOrGaspCPF = (cpfcnpj, format = true) => {
  if (cpfcnpj && cpfcnpj.length > 1) {
    if (cpfcnpj[1] === '-') {
      const ar = cpfcnpj.split('-');
      if (ar.length === 3) {
        cpfcnpj = ar[0] === '1' ? ar[1] : ar[2];
      }
    }
  }
  return getCPFCNPJ(cpfcnpj, format);
};

export const normalizedString = str => {
  return String(str)
    .normalize('NFD')
    .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
    .toLowerCase();
};

export const getCurrentDate = () => {
  const d = new Date();
  return `${d.getDate()}/${d.getMonth()}/${d.getFullYear()}`;
};

export function toStringDate(date) {
  if (typeof date === 'string' || date instanceof String) {
    date = new Date(date);
  }
  if (date) {
    return (
      `0${date.getDate()}`.slice(-2) +
      '/' +
      `0${date.getMonth() + 1}`.slice(-2) +
      '/' +
      date.getFullYear().toString().slice(-2)
    );
  } else {
    return '';
  }
}

export const sortDate = (rowA, rowB, columnId) => {
  const dateA = moment(rowA.values[columnId], 'DD/MM/YYYY');
  const dateB = moment(rowB.values[columnId], 'DD/MM/YYYY');
  return dateA.diff(dateB, 'days');
};

export const sortNumber = (rowA, rowB, columnId) => {
  const numA = parseInt(onlyNumbersString(rowA.values[columnId])) ?? 0;
  const numB = parseInt(onlyNumbersString(rowB.values[columnId])) ?? 0;
  return numB - numA;
};

export function toStringPicker(date) {
  return moment(new Date(date)).format('YYYY-MM-DD');
}

export function toStringTime(date, withSeconds) {
  if (typeof date === 'string' || date instanceof String) {
    date = new Date(date);
  }
  if (date) {
    let time = `0${date.getHours()}`.slice(-2) + (withSeconds ? ':' : 'h') + `0${date.getMinutes()}`.slice(-2);
    if (withSeconds) {
      time = `${time}:${`0${date.getSeconds()}`.slice(-2)}`;
    }
    return time;
  } else {
    return '';
  }
}

export function toStringDateTime(date, separator) {
  return `${toStringDate(date)}${separator ?? ' '}${toStringTime(date, false)}`;
}

export function toStringDateTimeSeconds(date, separator) {
  return `${toStringDate(date)}${separator ?? ' '}${toStringTime(date, true)}`;
}

export function toServerDateTime(date) {
  if (typeof date === 'string' || date instanceof String) {
    if (date.includes('/')) {
      date = moment(date, 'DD/MM/YYYY');
    } else if (date.includes('-')) {
      date = moment(date, 'YYYY-MM-DD');
    } else {
      date = moment(date);
    }
  }
  return date?.toISOString();
}

export function toStringMoney(number) {
  var formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return formatter.format(number ?? 0);
}

export const formatReal = value => {
  if (value == null) return '';
  if (typeof value !== 'string') value = value.toString();
  const intValue = parseInt(value.replace(/\D/g, ''));
  return toStringMoney(intValue / 100)
    .replace('R$', '')
    .trim();
};

export function onlyNumbersString(str) {
  if (str) {
    return str.toString().replace(/\D/g, '');
  } else {
    return str;
  }
}

export function condenseName(str) {
  if (str) {
    const arr = str.toString().split(' ');
    let index = 0;
    while (index < arr.length) {
      const condense = index > 0 && index < arr.length - 1;
      if (arr[index].length <= 2) {
        arr.splice(index, 1);
      } else {
        arr[index] = arr[index].charAt(0).toUpperCase() + (condense ? '.' : arr[index].slice(1));
        index++;
      }
    }
    return arr.join(' ');
  } else {
    return str;
  }
}

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== '' && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};

export const stringifyFilters = filterObj => {
  let queryString = '';

  if (filterObj) {
    for (let prop in filterObj) {
      if (
        (Array.isArray(filterObj[prop]) && filterObj[prop].length !== 0) ||
        (!Array.isArray(filterObj[prop]) &&
          typeof filterObj[prop] !== 'undefined' &&
          filterObj[prop] !== null &&
          filterObj[prop] !== '')
      ) {
        queryString += queryString ? '&' : '?';
        queryString += `${prop}=${Array.isArray(filterObj[prop]) ? filterObj[prop].join(',') : filterObj[prop]}`;
      }
    }
  }

  return queryString;
};

export const createAttribute = (name, obj) => {
  if (!obj.hasOwnProperty(name)) {
    obj[name] = {};
  }

  return obj;
};

export const getCookie = key => {
  var b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
};

export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const stateOptions = {
  _default: 'Selecione',
  Acre: 'AC',
  Alagoas: 'AL',
  Amapá: 'AP',
  Amazonas: 'AM',
  Bahia: 'BA',
  Ceará: 'CE',
  'Distrito Federal': 'DF',
  'Espírito Santo': 'ES',
  Goiás: 'GO',
  Maranhão: 'MA',
  'Mato Grosso': 'MT',
  'Mato Grosso do Sul': 'MS',
  'Minas Gerais': 'MG',
  Pará: 'PA',
  Paraíba: 'PB',
  Paraná: 'PR',
  Pernambuco: 'PE',
  Piauí: 'PI',
  'Rio de Janeiro': 'RJ',
  'Rio Grande do Norte': 'RN',
  'Rio Grande do Sul': 'RS',
  Rondônia: 'RO',
  Roraima: 'RR',
  'Santa Catarina': 'SC',
  'São Paulo': 'SP',
  Sergipe: 'SE',
  Tocantins: 'TO',
};

const graphNames = [
  { key: null, value: 'Não informado' },
  { key: 'bacen', value: 'Bacen' },
  { key: 'email', value: 'E-mail' },
  { key: 'fone', value: 'Fone' },
  { key: 'whatsapp', value: 'WhatsApp' },
  { key: 'loja', value: 'Loja' },
  { key: 'reclameaqui', value: 'ReclameAqui' },
  { key: 'approved', value: 'Aprovada' },
  { key: 'denied', value: 'Negada' },
  { key: 'canceled', value: 'Cancelada' },
  { key: 'margin', value: 'Margem' },
  { key: 'pending', value: 'Pendente' },
  { key: 'signature', value: 'Assinatura' },
  { key: 'under_analysis', value: 'Em Análise' },
  { key: 'banco', value: 'Banco' },
  { key: 'corretora', value: 'Corretora' },
  { key: 'audi_procon', value: 'Audi Procon' },
  { key: 'ouvidoria', value: 'Ouvidoria' },
  { key: 'procon', value: 'Procon' },
  { key: 'new', value: 'Novo' },
  { key: 'refine', value: 'Refin' },
  { key: 'sale', value: 'Compra' },
  { key: 'cancelado', value: 'Cancelado' },
  { key: 'informacao', value: 'Informação' },
  { key: 'retido', value: 'Retido' },
  { key: 'transferido', value: 'Transferido' },
  { key: 'nao_contratou', value: 'Não contratou' },
  { key: 'cancelamento', value: 'Cancelamento' },
  { key: 'nao_solicitado', value: 'Não solicitado' },
  { key: 'analise', value: 'Análise' },
  { key: 'autorizado', value: 'Autorizado' },
  { key: 'evidencia', value: 'Evidência' },
  { key: 'nao_autorizado', value: 'Não autorizado' },
  { key: 'reembolsado', value: 'Reembolsado' },
  { key: 'sem_debitos', value: 'Sem débitos' },
  { key: 'banrisul', value: 'Banrisul' },
  { key: 'bbrasil', value: 'BBrasil' },
  { key: 'caixa', value: 'Caixa' },
  { key: 'bradesco', value: 'Bradesco' },
  { key: 'itau', value: 'Itaú' },
  { key: 'santander', value: 'Santander' },
];

export const formateGraphNames = name => {
  const item = graphNames.find(item => name === item.key);
  return item ? item.value : name;
};

export const formateDataNames = input => {
  const shortDatePattern = /^[a-zA-Z]{3}\/\d{2}$/;

  if (shortDatePattern.test(input)) {
    const months = {
      jan: 'Jan',
      feb: 'Fev',
      mar: 'Mar',
      apr: 'Abr',
      may: 'Mai',
      jun: 'Jun',
      jul: 'Jul',
      aug: 'Ago',
      sep: 'Set',
      oct: 'Out',
      nov: 'Nov',
      dec: 'Dez',
    };

    const [month, year] = input.toLowerCase().split('/');
    const formattedMonth = months[month];

    return formattedMonth ? `${formattedMonth}/${year}` : input;
  }

  const fullMonths = {
    January: 'Janeiro',
    February: 'Fevereiro',
    March: 'Março',
    April: 'Abril',
    May: 'Maio',
    June: 'Junho',
    July: 'Julho',
    August: 'Agosto',
    September: 'Setembro',
    October: 'Outubro',
    November: 'Novembro',
    December: 'Dezembro',
  };

  if (fullMonths[input]) {
    return fullMonths[input];
  }

  return input;
};

const systemColors = [
  { key: null, value: '#000000' },
  { key: 'approved', value: '#4CAF50' },
  { key: 'denied', value: '#F44336' },
  { key: 'canceled', value: '#707070' },
  { key: 'margin', value: '#004C30' },
  { key: 'pending', value: '#FF9800' },
  { key: 'signature', value: '#5D6B00' },
  { key: 'under_analysis', value: '#00BCD4' },
  { key: 'bacen', value: '#3F51B5' }, // Indigo
  { key: 'email', value: '#9C27B0' }, // Purple
  { key: 'fone', value: '#673AB7' }, // Deep Purple
  { key: 'whatsapp', value: '#8BC34A' }, // Light Green
  { key: 'loja', value: '#FFC107' }, // Amber
  { key: 'reclameaqui', value: '#795548' }, // Brown
  { key: 'banco', value: '#FF5722' }, // Deep Orange
  { key: 'corretora', value: '#607D8B' }, // Blue Grey
  { key: 'audi_procon', value: '#009688' }, // Teal
  { key: 'ouvidoria', value: '#E91E63' }, // Pink
  { key: 'procon', value: '#03A9F4' }, // Light Blue
];

export const matchColor = name => {
  const item = systemColors.find(item => name === item.key);
  return item ? item.value : null;
};

const weekDays = [
  { key: 'Sunday', value: 'Domingo' },
  { key: 'Monday', value: 'Segunda' },
  { key: 'Tuesday', value: 'Terça' },
  { key: 'Wednesday', value: 'Quarta' },
  { key: 'Thursday', value: 'Quinta' },
  { key: 'Friday', value: 'Sexta' },
  { key: 'Saturday', value: 'Sábado' },
];

export const matchWeekDays = name => {
  const item = weekDays.find(item => name === item.key);
  return item ? item.value : null;
};

export const menuArray = [
  {
    menu: 'Menu Empréstimos',
    children: [
      {
        sub_menu: 'Controle Geral, Físico e Produção',
        sub_profile: 'proposals',
      },
      {
        sub_menu: 'Cartão Consig',
        sub_profile: 'credit_card',
      },
      {
        sub_menu: 'Simulador',
        sub_profile: 'loan_simulator',
      },
    ],
  },
  {
    menu: 'Menu Seguros',
    children: [
      {
        sub_menu: 'Apólice e Informativos',
        sub_profile: 'insurance',
      },
      {
        sub_menu: 'Atendimento de Certificado',
        sub_profile: 'certificates',
      },
      {
        sub_menu: 'Atendimento de Fonado',
        sub_profile: 'fonado',
      },
    ],
  },
  {
    menu: 'Menu Parceiros',
    profile: 'partners',
    children: [
      {
        sub_menu: 'Cadastro',
        sub_profile: 'partners',
      },
      {
        sub_menu: 'Usuários',
        sub_profile: 'partners_users',
      },
    ],
  },
  {
    menu: 'Menu Portal Cliente',
    profile: 'portalcliente',
    children: [
      {
        sub_menu: 'Clientes e Empresas',
        sub_profile: 'portalcliente',
      },
    ],
  },
  { menu: 'Menu Clientes', profile: 'clients' },
  {
    menu: 'Menu Convênios',
    children: [
      {
        sub_menu: 'Cadastro',
        sub_profile: 'products',
      },
      {
        sub_menu: 'Manuais',
        sub_profile: 'files',
      },
      {
        sub_menu: 'Mensagens',
        sub_profile: 'messages',
      },
      {
        sub_menu: 'Tab. Taxas',
        sub_profile: 'interest_rate',
      },
      {
        sub_menu: 'Taxas Cartão',
        sub_profile: 'credit_card_rate',
      },
    ],
  },
  {
    menu: 'Menu Relatórios',
    children: [
      {
        sub_menu: 'Empréstimo',
        sub_profile: 'panel_loans',
      },
      {
        sub_menu: 'Previdência',
        sub_profile: 'panel_foresight',
      },
      {
        sub_menu: 'Seguro',
        sub_profile: 'panel_insurance',
      },
    ],
  },
  { menu: 'Menu Sites', profile: 'sites' },
];

export const userPermissions = [
  'admin',
  'insurance_read',
  'insurance_write',
  'proposals_read',
  'proposals_write',
  'partners_read',
  'partners_write',
  'files_read',
  'files_write',
  'products_read',
  'products_write',
  'messages_read',
  'messages_write',
  'certificates_read',
  'certificates_write',
  'partners_users_read',
  'clients_read',
  'sites_read',
  'sites_write',
  'fonado_read',
  'fonado_write',
  'panel_loans_operational',
  'panel_loans_manager',
  'panel_foresight_operational',
  'panel_foresight_manager',
  'panel_insurance_operational',
  'panel_insurance_manager',
  'interest_rate_read',
  'interest_rate_write',
  'credit_card_read',
  'credit_card_write',
  'credit_card_rate_read',
  'credit_card_rate_write',
  'proposals_export',
  'credit_card_export',
  'fonado_export',
  'portalcliente_read',
  'portalcliente_write',
];
